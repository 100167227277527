<template>
  <div class="nav-footer">
    <div class="w">
      <!-- 上层 -->
      <div class="f-s">
        <!-- 左侧logo -->
        <div class="s-l">
          <h4>杭州朝黛网络技术有限公司</h4>
          <p>联系电话：18668197361</p>
          <p>联系邮箱：yaomaolin2021@163.com</p>
          <p>注册地址：浙江省杭州经济技术开发区下沙街道东城大厦8幢1204室</p>
        </div>
        <!-- 右侧列表 -->
        <div class="s-r">
          <a href="/">网站首页</a>
          <a href="/#/ac1">动漫产品</a>
          <a href="/#/info">关于我们</a>
          <!-- <a href="/#/xy2">联系我们</a> -->
          <a href="/#/xy">用户协议</a>
          <!-- <a href="/#/info2">加入我们</a> -->
        <a href="/#/xy2">未成年人限制条款</a>
        <!-- <a href="/#/xy4">自审制度</a> -->
          <a href="https://www.shdf.gov.cn/shdf/channels/740.html">扫黄打非入口</a>

          <a href="https://www.12377.cn/">举报专区</a>

          <!-- <a href="/imgs/zz.jpg" target="_blink">营业执照</a> -->

          <a href="http://cyberpolice.mps.gov.cn/wfjb/">网络110报警服务</a>
          <a href="https://www.12377.cn/">中国互联网举报中心</a>
          <a href="https://www.12377.cn/">网络有害信息举报</a>
          <a href="https://www.12377.cn/node_548446.htm">网络举报APP下载</a>

          <a href="https://jbts.mct.gov.cn/">全国文化和旅游市场网上举报投诉</a>

          <a href="https://www.beian.gov.cn/portal/index.do">经营性网站备案信息</a>
        </div>
      </div>
      <!-- 下层 -->
      <div class="f-x">
        © 2021-2023 zhaodaiwangluo.com 版权保护声明 --
        <a href="https://beian.miit.gov.cn/#/Integrated/index"
          >网站备案：浙ICP备18036255号-2</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  props: {},
  data() {
    return {
      // 网页的基础数据配置
      dataSourc: {},
    };
  },
  mounted() {
    // 查询网站基本配置数据
    this.axios.get("http://8.142.155.68:8099/api/data/sourc/selectAll").then((res) => {
      this.dataSourc = res.data[0];
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-footer {
  width: 100%;
  background: rgb(37, 45, 69);
  color: #fff;
  .w {
    // 上层
    .f-s {
      // width: 100%;
      height: 270px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // 左侧
      .s-l {
        width: 50%;
        h4 {
          font-size: 21px;
          font-weight: bold;
        }
        p {
          font-size: 14px;
          margin: 15px 0;
        }
      }
      // 右侧
      .s-r {
        width: 90%;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        a {
          font-size: 14px;
          margin: 10px 20px;
          color: #fff;
          text-decoration: none;
          &:hover {
            color:$colorK;
          }
        }
      }
    }
    // 下层
    .f-x {
      // width: 100%;
      height: 50px;
      border-top: 1px solid #fafafa;
      padding-top: 10px;
      box-sizing: border-box;
      text-align: center;
      a {
        color: #fff;
      }
    }
  }
}
</style>
