<template>
  <div class="info">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <!-- 定宽 -->
    <div class="container">
      <!-- 右侧 内容 -->
      <div class="info-2">
        <h3>关于我们</h3>
        <!-- 下环线 -->
        <div class="bink"></div>

        <!-- banenr -->
        <div class="i2-banner">
          <img src="/imgs/us-banner.jpg" alt="" />
        </div>
        <div class="i2-con">
          <h3>公司名称：杭州朝黛网络技术有限公司</h3>
          <h3>联系邮箱：yaomaolin2021@163.com</h3>
          <h3>联系电话：18668197361</h3>
          <h3>ICP备案：浙ICP备18036255号-2</h3>
          <h3>公司地址：浙江省杭州经济技术开发区下沙街道东城大厦8幢1204室</h3>
          <p>
           杭州朝黛网络技术有限公司成立于2018年03月30日，注册地位于浙江省杭州经济技术开发区下沙街道东城大厦8幢1204室，经营范围包括技术开发、技术服务、技术咨询、成果转让：网络信息技术、电子产品、计算机软硬件；服务：企业营销策划，图文设计，企业形象策划，广告设计，制作、代理、发布（除网络广告发布），经营性互联网文化服务，文化艺术交流活动策划（除演出及演出中介），市场营销策划，教育信息咨询（不含出国留学和中介服务），成年人的非证书劳动职业技能培训，经济信息咨询（除商品中介）；食品销售；网上批发、零售：工艺美术品、文化用品、办公用品、音响器材、体育用品、化妆品（除分装）、家具、服饰、母婴用品（除奶粉）、包装材料、电子产品（除国家专控）、数码产品、日用百货、通讯器材（除国家专控）、计算机软硬件。（依法须经批准的项目，经相关部门批准后方可开展经营活动）
          </p>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "info",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "/",
    };
  },
  mounted() {
      document.title = "杭州朝黛网络技术有限公司 ";
  },
  methods: {
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.info {
  width: 100%;
  background-color: #f0f0f0;
  // banner
  .i2-banner {
    width: 100%;
    height: 250px;
    background-color: pink;
    margin-bottom: 120px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  // 定宽布局
  .container {
    @include flex();
    // height: 800px;
    margin-bottom: 30px;
    // 左侧
    .info-1 {
      width: 30%;
      height: 100%;
      margin-right: 20px;
      // background-color: red;
      ul {
        width: 190px;
        background-color: #fff;
        border-bottom: 2px solid #dfe1e2;
        border-radius: 3px;
        padding: 13px 15px;
        margin: 0 auto;
        li {
          width: 90%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: bold;

          box-sizing: border-box;
          margin: 10px auto;
          cursor: pointer;
          padding-left: 20px;
          &.active {
            background-color: #3890e1;
            color: #fff;
          }
        }
      }
    }
    // 右侧
    .info-2 {
      width: 70%;
      height: 100%;
      background: #fff;
      padding: 40px;
      box-sizing: border-box;
      text-align: center;
      h3 {
        font-size: 24px;
      }
      // 下划线
      .bink {
        width: 30px;
        height: 3px;
        background-color: #000;
        margin: 10px auto;
      }

      // banner
      .i2-banner {
        width: 100%;
        height: 300px;
        background: url("/imgs/b1.jpg") no-repeat center;
        background-size: cover;
        margin: 30px auto;
      }

      // 文章
      .i2-con {
        p {
          font-size: 13px;
          line-height: 3.2em;
          color: #4e4b4b;
          margin: 15px auto;
          padding: 10px;
          box-sizing: border-box;
          text-align: left;
        }
        h3 {
          font-size: 18px;
          margin: 15px auto;
        }
      }
    }
  }
}
</style>
