import { render, staticRenderFns } from "./xy.vue?vue&type=template&id=54f98f45&scoped=true&"
import script from "./xy.vue?vue&type=script&lang=js&"
export * from "./xy.vue?vue&type=script&lang=js&"
import style0 from "./xy.vue?vue&type=style&index=0&id=54f98f45&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54f98f45",
  null
  
)

export default component.exports